<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i>
                    <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL CHECK LIST DE INFORMACION</span>
                    <hr />
                    <b-row class="justify-content-center text-center">
                        <b-col cols="4" class=" my-2 icon-custom">
                            <i class="fas fa-file-powerpoint fa-3x ico"> </i>
                            <br />
                            <span class="lg-numero">{{listaCheckListInicial.length }}</span>
                            <br />
                            <span class="text-muted h6">Planes de auditoria</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Check List de Informacion </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{ name: 'Evaluacion de Proveedores' }">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br />Nueva Check list
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i>
                    <span class="h5"> Check List Registrados</span>
                </CCardHeader>

                <CCardBody>
                    <div>
                        <b-row>
                            <b-col md="12">
                                <b-tabs variant="pills">
                                    <b-tab active>
                                        <template slot="title">
                                            <i class="fas fa-file-powerpoint fa-md mr-1"></i> Lista de Informacion
                                        </template>
                                        <b-row class="mt-3">
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7">
                                            </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search  my-0 mx-0"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaCheckListInicial" :fields="headerCheckListInicial" :current-page="currentPage" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" bordered hover show-empty mediun responsive outlined @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template #table-colgroup="headerCheckListInicial">
                                                        <col v-for="field in headerCheckListInicial.fields" :key="field.key" :style="`width: ${field.width}`" />
                                                    </template>
                                                    <template v-slot:cell(fechaCreacion)="data">
                                                        {{ data.value.substr(0, 10) }}
                                                    </template>
                                                    <template #cell(acciones)="param">
                                                        <b-button :to="{name: 'Evaluacion de Proveedores',params: { id: param.item.idPackCheckListInicial },}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-button>
                                                        <b-button @click="eliminarCheckListInicial(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                </b-tabs>
                            </b-col>
                        </b-row>
                    </div>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from 'axios';
import CONSTANTES from '@/Constantes.js';

export default {
    data() {
        return {
            headerCheckListInicial: [{
                    key: 'index',
                    label: 'N°',
                    class: 'text-center',
                },
                {
                    key: 'codigo',
                    label: 'Código',
                    class: 'text-center',
                },
                {
                    key: 'fechaCreacion',
                    label: 'Fecha de creación',
                    class: 'text-center',
                }, {
                    key: 'acciones',
                    label: 'Opciones',
                    class: 'text-center',
                },
            ],
            listaCheckListInicial: [],
            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            datosSession: {
                idCliente: null,
            }
        };
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        eliminarCheckListInicial(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el registro?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/eliminar-pack-checklist-inicial", {
                            idPackCheckListInicial: param.item.idPackCheckListInicial
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarCheckListInicial();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        listarCheckListInicial() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/listar-pack-checklist-inicial', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    },
                })
                .then(function (response) {
                    me.listaCheckListInicial = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                });
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarCheckListInicial();
            

        }
    }
}
</script>
